import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="camera"
export default class extends Controller {
  static targets = [ "video", "canvas", "output", "imagemAtual", "botaoIniciarCamera", "botaoTirarFoto", "botaoCancelar" ]

  async iniciarCamera(e) {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false })
      this.videoTarget.srcObject = stream

      this._atualizarCameraLigada()
    } catch (ex) {
      alert("Não foi possível iniciar a câmera!")
    }
  }

  tirarFoto(e) {
    const canvas = this.canvasTarget
    const video = this.videoTarget
    canvas.height = video.clientHeight
    canvas.width = video.clientWidth
    canvas.getContext("2d").drawImage(this.videoTarget, 0, 0, video.clientWidth, video.clientHeight)
    canvas.toBlob(this._tratarBlob())

    canvas.classList.remove("d-none")

    this._desativarCamera()
    this._atualizarCameraDesligada()
  }

  cancelar() {
    this._desativarCamera()
    this._atualizarCameraDesligada()

    this.canvasTarget.classList.add("d-none")

    if (this.hasImagemAtualTarget) {
      this.imagemAtualTarget.classList.remove("d-none")
    }
  }

  disconnect() {
    this._desativarCamera()
  }

  _tratarBlob() {
    const output = this.outputTarget

    return (blob) => {
      const arquivo = new File([blob], "image.jpeg", { type: blob.type })

      let list = new DataTransfer()
      list.items.add(arquivo)
      output.files = list.files
    }
  }

  _desativarCamera() {
    if (this.videoTarget.srcObject) {
      this.videoTarget.srcObject.getTracks().forEach( (track) => track.stop() )
    }
  }

  _atualizarCameraLigada() {
    this.videoTarget.classList.remove("d-none")
    this.botaoTirarFotoTarget.classList.remove("d-none")
    this.botaoCancelarTarget.classList.remove("d-none")

    this.canvasTarget.classList.add("d-none")
    this.botaoIniciarCameraTarget.classList.add("d-none")

    if (this.hasImagemAtualTarget) {
      this.imagemAtualTarget.classList.add("d-none")
    }
  }

  _atualizarCameraDesligada() {
    this.videoTarget.classList.add("d-none")
    this.botaoTirarFotoTarget.classList.add("d-none")
    this.botaoCancelarTarget.classList.add("d-none")

    this.botaoIniciarCameraTarget.classList.remove("d-none")
  }
}
